const baseUrl = "https://xssvwicjvk.ap-south-1.awsapprunner.com"

const apiRoutes = {
    login: baseUrl+"/login",
    getImages: `${baseUrl}/get-images`,
    contents: `${baseUrl}/contents`,
    createClient: `${baseUrl}/projects/create-client`,
    createProject: `${baseUrl}/projects/create-project`,
    createModel: `${baseUrl}/projects/create-model`,
    deleteProject: `${baseUrl}/delete-project`,
    uploadImage: `${baseUrl}/upload-image`,
    uploadZip: `${baseUrl}/upload-zip`
};

export default apiRoutes;
